import type { BrandTestimonialDefinition } from "~/business-areas/brand/brand.model";

export function useTestimonialList(filters: {
  page?: string;
  author?: string;
}) {
  return useLazyFetch("/api/testimonials", {
    query: filters,
    transform(data): BrandTestimonialDefinition[] {
      return data.map((item) => {
        return {
          id: item.author,
          authorFullName: item.author,
          authorAvatar: item.author_avatar,
          authorJob: item.author_job_title,
          authorCompanyLogo: item.author_company_logo,
          authorCompanyName: item.author_company,
          content: item.content,
          rating: item.rating,
        };
      });
    },
    default: () => [] as BrandTestimonialDefinition[],
  });
}
