<template>
  <figure class="flex items-start gap-2">
    <app-image
      v-if="author.authorAvatar"
      :alt="author.authorFullName"
      class="bg-white"
      :height="200"
      :src="author.authorAvatar"
      :style="{
        width: `${avatarSize}px`,
        height: `${avatarSize}px`,
        clipPath: shape,
      }"
      :width="200"
    />

    <figcaption class="flex-auto">
      <p class="flex items-center gap-4 font-bold">
        {{ author.authorFullName }}
      </p>
      <p class="text-sm" v-html="author.authorJob" />

      <app-image
        v-if="!noCompanyLogo && author.authorCompanyLogo"
        :alt="author.authorCompanyName"
        class="h-[25px] max-w-[70px] rounded object-contain"
        :height="25"
        :src="author.authorCompanyLogo"
      />
    </figcaption>
  </figure>
</template>

<script lang="ts" setup>
import type { BrandTestimonialDefinition } from "~/business-areas/brand/brand.model";
import * as shapes from "~/business-areas/brand/brand-shapes";

withDefaults(
  defineProps<{
    author: Omit<BrandTestimonialDefinition, "content">;
    noCompanyLogo?: boolean;
    avatarSize?: number;
  }>(),
  {
    avatarSize: 70,
  },
);

const shape = pickRandomElementInArray(Object.values(shapes));
</script>
